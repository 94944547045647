import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "login",
    component: () => import("../views/LogInPage.vue"),
  },
  {
    path: "/terms_of_use",
    name: "terms_of_use",
    component: () => import("../views/TermsOfUse.vue"),
  },
  {
    path: "/walkers",
    name: "walkerList",
    component: () => import("../views/WalkersList.vue"),
  },
  {
    path: "/TherapistRegister",
    name: "TherapistRegister",
    component: () => import("../views/TherapistRegister.vue"),
  },
  {
    path: "/ChangePass",
    name: "ChangePass",
    component: () => import("../views/ChangePass.vue"),
  },
  {
    path: "/FAQ",
    name: "FAQ",
    component: () => import("../views/FAQ.vue"),
  },
  {
    path: "/Inquiries",
    name: "Inquiries",
    component: () => import("../views/InquiriesPage.vue"),
  },
  {
    path: "/walkers/:id/MovieRecording",
    name: "MovieRecording",
    component: () => import("../views/MovieRecording.vue"),
  },
  // 動画アップロードテスト用
  // {
  //   path: "/walkers/:id/FileRecordInput",
  //   name: "FileRecordInput",
  //   component: () => import("../views/FileRecordInput.vue"),
  // },
  //
  {
    path: "/walkers/:id/AnalysisResultsList",
    name: "AnalysisResultsList",
    component: () => import("../views/AnalysisResultsList.vue"),
    props: (route) => ({
      query: route.query.fallen_register_is_forget === "true",
    }),
  },
  {
    path: "/walkers/:id/AnalysisResultsList/ComparePlayBack/:top_video_id/:bottom_video_id",
    name: "ComparePlayBack",
    component: () => import("../views/ComparePlayBack.vue"),
  },
  {
    path: "/walkers/:id/AnalysisResultsList/ExportCompareGaitReport/:top_video_id/:bottom_video_id",
    name: "ExportCompareGaitResult",
    component: () => import("../views/ExportCompareGaitResult.vue"),
  },

  {
    path: "/walkers/:id/AnalysisResultsList/:video_id/Summary",
    name: "AnalysisResultsListSummary",
    component: () => import("../views/AnalysisResultsListSummary.vue"),
  },
  //
  {
    path: "/walkers/:id/AnalysisResultsList/:video_id/Summary/ExportGaitResult/:therapist_id",
    name: "ExportGaitAnalysisReport",
    component: () => import("../views/ExportGaitAnalysisReport.vue"),
  },
  // mock page
  {
    path: "/walkers/:id/AnalysisResultsList/:video_id/Summary/GaitAnalysisResultReport/:therapist_id",
    name: "GaitAnalysisResultReport",
    component: () => import("../views/GaitAnalysisResultReport.vue"),
  },
  //
  {
    path: "/walkers/:id/AnalysisResultsList/:video_id/Summary/CommentEdit",
    name: "SummaryCommentEdit",
    component: () => import("../views/CommentEdit.vue"),
  },
  // FullScreenVideo page
  {
    path: "/walkers/:id/AnalysisResultsList/:video_id/Summary/FullScreenVideo",
    name: "FullScreenVideo",
    component: () => import("../views/FullScreenVideo.vue"),
  },
  //
  {
    path: "/walkers/:id/AnalysisResultsList/:video_id/Summary/AnalysisResultTable",
    name: "AnalysisResultTable",
    component: () => import("../views/AnalysisResultTable.vue"),
  },
  {
    path: "/walkers/:id/AnalysisResultsList/:video_id/Summary/JointMotionTimeLine",
    name: "JointMotionTimeLine",
    component: () => import("../views/JointMotionTimeLine.vue"),
  },
  {
    path: "/walkers/:id/AnalysisConditionEditor",
    name: "AnalysisConditionEditor",
    component: () => import("../views/AnalysisConditionEditor.vue"),
  },
  {
    path: "/walkers/:id/FallDownRegister",
    name: "FallDownRegister",
    component: () => import("../views/FallDownRegister.vue"),
    props: (route) => ({
      query: route.query.fallen_register_is_forget === "true",
    }),
  },
  {
    path: "/walkers/:id/details",
    name: "WalkerDetail",
    component: () => import("../views/WalkerEdit.vue"),
    props: {
      title: "利用者詳細",
      can_edit: false,
      about_this_page: "check walker's detail",
    },
  },
  {
    path: "/walkers/:id/DetailsEdit",
    name: "WalkerDetailEdit",
    component: () => import("../views/WalkerEdit.vue"),
    props: {
      title: "利用者編集",
      can_edit: true,
      about_this_page: "edit walker",
    },
  },
  {
    path: "/walkers/WalkerRegister",
    name: "WalkerRegister",
    component: () => import("../views/WalkerEdit.vue"),
    props: {
      title: "利用者登録",
      can_edit: false,
      about_this_page: "register walker",
    },
  },
  {
    path: "/walkers/:id/details/ConsentForm",
    name: "ConsentForm",
    component: () => import("../views/ConsentForm.vue"),
  },
];

const router = new VueRouter({
  routes,
});

export default router;
