import Vue from "vue";
import Vuetify from "vuetify/lib/framework";

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    dark: true,
    // themes: {
    //   dark: {
    //     // primary: "#2bd3d6",
    //     background: "#505b7c",
    //   },
    // },
  },
});
