<template>
  <v-footer :color="footer_back_color" class="text-center d-print-none">
    <v-img
      height="2rem"
      contain
      :src="
        require(`@/assets/JTEKT_logo/JTEKT_logo_${jtekt_logo_type}_type.jpg`)
      "
    />
  </v-footer>
</template>

<script>
export default {
  computed: {
    jtekt_logo_type() {
      return this.$vuetify.theme.dark ? "Negative" : "Positive";
    },
    footer_back_color() {
      return this.$vuetify.theme.dark ? "black" : "white";
    },
  },
};
</script>
