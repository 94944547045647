<template>
  <v-app :style="{ background: $vuetify.theme.themes[theme].background }">
    <v-main>
      <v-container fluid>
        <router-view />
      </v-container>
    </v-main>
    <JTEKTLogo v-if="current_page_name !== 'MovieRecording'" />
  </v-app>
</template>

<script>
import JTEKTLogo from "@/components/Recycle/Atoms/JTEKTLogo.vue";

export default {
  components: {
    JTEKTLogo,
  },
  computed: {
    theme() {
      return this.$vuetify.theme.dark ? "dark" : "light";
    },
    current_page_name() {
      return this.$route.name;
    },
  },
};
</script>
