import Vue from "vue";
import App from "./App.vue";
import vuetify from "./plugins/vuetify";
import router from "./router";
import "./registerServiceWorker";
import axios from "axios";
import VueAxios from "vue-axios";
import VueSignaturePad from "vue-signature-pad";

axios.defaults.baseURL = process.env.VUE_APP_VALI_WALK_API_BASE_URL;
Vue.use(VueAxios, axios);
Vue.use(VueSignaturePad);
Vue.config.productionTip = false;
// ページ間を移動する時、to:移動先ページの情報を取得する
router.beforeEach(async (to, from, next) => {
  const token = localStorage.getItem("token");
  // localStorageにAPI接続に使う「token」を格納
  axios.defaults.headers.common["Authorization"] = token;
  if (to.name !== "login" && token) {
    // アプリを操作しているか生存確認
    axios.put("/keep-login").catch(() => {
      localStorage.removeItem("token");
      localStorage.removeItem("facility_id");
      localStorage.removeItem("CloudFront_Key_Pair_Id");
      localStorage.removeItem("CloudFront_Policy");
      localStorage.removeItem("CloudFront_Signature");
      alert("タイムアウトが発生したためログアウトします。");
      next({ name: "login" });
    });
  }
  // from:移動元ページの情報を取得
  router["referrer"] = from;
  if (to.name !== "login" && !token) {
    next({ name: "login" });
  } else next();
});

new Vue({
  vuetify,
  router,
  render: (h) => h(App),
}).$mount("#app");
